<template>
	<section class="content-main">
		<!--基本信息-->
		<el-row>
			<el-col :span="12" style="padding-right:20px;min-width:750px">
				<el-form :model="form" label-width="140px" :rules="formRules" ref="form">
					
					<el-form-item label="标题" prop="title" clearable>
						<el-input v-model.trim="form.title"></el-input>
					</el-form-item>
					<el-form-item label="通知内容" prop="content" clearable>
						<!-- <el-input v-model.trim="form.content" type="textarea"></el-input> -->
                        <!-- <quilleditor v-model="form.content" ref="myTextEditor" :options="editorOption" @change="onChange"></quilleditor> -->
                        <quilleditor2
                            :value="form.content"
                            name="quilleditor2"
                            ref="myTextEditor"
                            >
                            </quilleditor2>
					</el-form-item>
					<el-form-item label="类型" prop="action">
						<el-select v-model="form.action" placeholder="请选择类型" clearable>
							<el-option v-for="i in actions" :key="i.value" :label="i.label" :value="i.value">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="门店ID" prop="receiver" clearable>
						<el-input v-model.trim="form.receiver" placeholder="指定门店发送消息，缺省则全部" ></el-input>
					</el-form-item>
					<el-form-item label="">
						<el-button type="primary" @click.native="addSubmit" :loading="Loading">添加</el-button>
						<el-button @click="goBack">取消</el-button>
					</el-form-item>
				</el-form>
			</el-col>
		</el-row>
	</section>
</template>

<script>
import { noticePush } from "../../../api/api";
import quillEditor2 from "@/components/quillEditor2";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

export default {
    data() {
        return {
            //表单验证规则
            formRules: {
                title: [
                    { required: true, message: "请输入标题", trigger: "blur" }
                ],
                content: [
                    {
                        required: true,
                        message: "请输入详细内容",
                        trigger: "blur"
                    }
                ],
                action: [
                    { required: true, message: "请选择类型", trigger: "blur" }
                ]
            },
            actions: [
                { value: 0, label: "消息通知" },
                { value: 1, label: "续费通知" },
                { value: 2, label: "费率切换" }
            ],
            //创建新增
            Loading: false,
            //新增界面数据
            form: {
                title: "",
                content: "",
                action: "",
                receiver: ""
            }
        };
    },
    components: {
        quilleditor2: quillEditor2
    },

    // computed: {
    //     editor() {
    //         return this.$refs.myTextEditor.quill;
    //     }
    // },
    methods: {
        //取消返回
        goBack() {
            this.$router.go(-1);
        },
        //新增
        addSubmit: function() {
            this.$refs.form.validate(async valid => {
                if (!valid) {
                    return;
                }
                this.Loading = true;
                try {
                    let res = await noticePush(this.form);
                    if (res.code == 1) {
                        this.goBack();
                    } else {
                        this.$message.error(res.msg);
                    }
                } catch (e) {
                    this.$message.error(e.message);
                }
                this.Loading = false;
            });
        },
        onEditorBlur() {
            //失去焦点事件
        },
        onEditorFocus() {
            //获得焦点事件
        },
        onEditorChange() {
            //内容改变事件
        }
    },
    mounted() {
        let match = location.hash.match(/store_id=(\d+)/);
        if (match) {
            this.form.receiver = match[1];
        }
        this.$refs.myTextEditor.$on("getContent", c => {
            this.form.content = c;
        });
    }
};
</script>
<style lang="scss" scoped>
.el-form-item__content {
    display: flex;
}

.avatar-uploader {
    width: 150px;
    height: 150px;
    display: flex;
    align-items: center;
    margin-right: 15px;
    float: left;
    border-radius: 6px;
    overflow: hidden;
    background-color: #efefef;
    position: relative;
}
.el-icon-plus:before {
    content: "";
}
.seeBigImg {
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: 44px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    border-top-left-radius: 8px;
    background-color: rgba(0, 0, 0, 0.8);
    color: #ffffff;
}
.avatar-uploader .el-upload {
    display: block;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    border: none;
}

.avatar-uploader .el-upload img {
    width: 100%;
}

.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}

.avatar-uploader-icon {
    font-size: 20px;
    color: #8c939d;
    width: 148px;
    height: 148px;
    line-height: 148px;
    text-align: center;
    border-radius: 6px;
    background-color: #fbfdff;
    border: 1px dashed #c0ccda;
}

.avatar {
    width: 100%;
    display: block;
}

p {
    padding: 8px 0;
}


</style>
