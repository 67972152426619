<template>
    <section>
        <quilleditor v-model="content"
                     ref="myTextEditor"
                     :options="editorOption"
                     @change="onChange"
                     @blur="onEditorBlur($event)"
                     @focus="onEditorFocus($event)"
                     @ready="onEditorReady($event)">
            <div id="toolbar2" slot="toolbar">
                <div v-if="tool!=0">
                    <span class="ql-formats"><button type="button" class="ql-bold" title="粗体"></button></span>
                    <span class="ql-formats"><button type="button" class="ql-italic" title="斜体"></button></span>
                    <span class="ql-formats"><button type="button" class="ql-underline" title="下划线"></button></span>
                    <span class="ql-formats"><button type="button" class="ql-strike" title="删除线"></button></span>
                    <span class="ql-formats"><button type="button" class="ql-blockquote" title="引用"></button></span>
                    <span class="ql-formats"><button type="button" class="ql-code-block" title="代码块"></button></span>
                    <span class="ql-formats"><button type="button" class="ql-header" value="1" title="H1标题"></button></span>
                    <span class="ql-formats"><button type="button" class="ql-header" value="2" title="H2标题"></button></span>
                    <span class="ql-formats"><button type="button" class="ql-list" value="ordered" title="有序列表"></button></span>
                    <span class="ql-formats"><button type="button" class="ql-list" value="bullet" title="无序列表"></button></span>
                    <span class="ql-formats"><button type="button" class="ql-script" value="sub" title="下标"></button></span>
                    <span class="ql-formats"><button type="button" class="ql-script" value="super" title="上标"></button></span>
                    <span class="ql-formats"><button type="button" class="ql-indent" value="-1" title="左缩进"></button></span>
                    <span class="ql-formats"><button type="button" class="ql-indent" value="+1" title="右缩进"></button></span>
                    <span class="ql-formats"><button type="button" class="ql-direction" value="rtl" title="描述"></button></span>
                    <span class="ql-formats" title="字号" >
					<select class="ql-size" style="width:120px">
						<option selected="">默认字号</option>
					  	<option value="12px">12px</option>
						<option value="14px">14px</option>
						<option value="15px">15px</option>
						<option value="16px">16px</option>
						<option value="18px">18px</option>
						<option value="24px">24px</option>
						<option value="32px">32px</option>
						<option value="40px">40px</option>
					</select>
				</span>
                    <span class="ql-formats" title="标题">
					<select class="ql-header">
						<option selected="selected">默认标题</option>
					  	<option value="1"></option>
					  	<option value="2"></option>
					  	<option value="3"></option>
					  	<option value="4"></option>
					  	<option value="5"></option>
					  	<option value="6"></option>
					</select>
				</span>
                    <span class="ql-formats mtop" title="字体颜色">
					<select class="ql-color">
					  <option selected="selected"></option>
					  <option value="#e60000"></option>
					  <option value="#ff9900"></option>
					  <option value="#ffff00"></option>
					  <option value="#008a00"></option>
					  <option value="#0066cc"></option>
					  <option value="#9933ff"></option>
					  <option value="#ffffff"></option>
					  <option value="#facccc"></option>
					  <option value="#ffebcc"></option>
					  <option value="#ffffcc"></option>
					  <option value="#cce8cc"></option>
					  <option value="#cce0f5"></option>
					  <option value="#ebd6ff"></option>
					  <option value="#bbbbbb"></option>
					  <option value="#f06666"></option>
					  <option value="#ffc266"></option>
					  <option value="#ffff66"></option>
					  <option value="#66b966"></option>
					  <option value="#66a3e0"></option>
					  <option value="#c285ff"></option>
					  <option value="#888888"></option>
					  <option value="#a10000"></option>
					  <option value="#b26b00"></option>
					  <option value="#b2b200"></option>
					  <option value="#006100"></option>
					  <option value="#0047b2"></option>
					  <option value="#6b24b2"></option>
					  <option value="#444444"></option>
					  <option value="#5c0000"></option>
					  <option value="#663d00"></option>
					  <option value="#666600"></option>
					  <option value="#003700"></option>
					  <option value="#002966"></option>
					  <option value="#3d1466"></option>
				  </select>
				</span>
                    <span class="ql-formats mtop" title="背景颜色">
					<select class="ql-background">
					 <option value="#000000"></option>
					 <option value="#e60000"></option>
					 <option value="#ff9900"></option>
					 <option value="#ffff00"></option>
					 <option value="#008a00"></option>
					 <option value="#0066cc"></option>
					 <option value="#9933ff"></option>
					 <option selected="selected"></option>
					 <option value="#facccc"></option>
					 <option value="#ffebcc"></option>
					 <option value="#ffffcc"></option>
					 <option value="#cce8cc"></option>
					 <option value="#cce0f5"></option>
					 <option value="#ebd6ff"></option>
					 <option value="#bbbbbb"></option>
					 <option value="#f06666"></option>
					 <option value="#ffc266"></option>
					 <option value="#ffff66"></option>
					 <option value="#66b966"></option>
					 <option value="#66a3e0"></option>
					 <option value="#c285ff"></option>
					 <option value="#888888"></option>
					 <option value="#a10000"></option>
					 <option value="#b26b00"></option>
					 <option value="#b2b200"></option>
					 <option value="#006100"></option>
					 <option value="#0047b2"></option>
					 <option value="#6b24b2"></option>
					 <option value="#444444"></option>
					 <option value="#5c0000"></option>
					 <option value="#663d00"></option>
					 <option value="#666600"></option>
					 <option value="#003700"></option>
					 <option value="#002966"></option>
					 <option value="#3d1466"></option>
				   </select>
				</span>
                    <span class="ql-formats" title="字体">
					<select class="ql-font">
					  <option selected="selected">默认字体</option>
					  <option value="serif"></option>
					  <option value="monospace"></option>
					</select>
				</span>
                    <span class="ql-formats"><button type="button" class="ql-align" value="" title="左对齐"></button></span>
                    <span class="ql-formats"><button type="button" class="ql-align" value="center" title="居中"></button></span>
                    <span class="ql-formats"><button type="button" class="ql-align" value="right" title="右对齐"></button></span>
                    <span class="ql-formats"><button type="button" class="ql-align" value="justify" title="对齐行"></button></span>
                    <!--<span class="ql-formats mtop">-->
                    <!--<select class="ql-align">-->
                    <!--<option selected="selected"></option>-->
                    <!--<option value="center"></option>-->
                    <!--<option value="right"></option>-->
                    <!--<option value="justify"></option>-->
                    <!--</select>-->
                    <!--</span>-->
                    <span class="ql-formats"><button type="button" class="ql-link" title="添加超链接"></button></span>
                    <!--图片按钮点击事件-->
                    <!--<span class="ql-formats"><button type="button" @click="imgClick">-->
                    <!--<svg viewBox="0 0 18 18"> <rect class="ql-stroke" height="10" width="12" x="3" y="4"></rect> <circle class="ql-fill" cx="6" cy="7" r="1"></circle> <polyline class="ql-even ql-fill" points="5 12 5 11 7 9 8 10 11 7 13 9 13 12 5 12"></polyline> </svg>-->
                    <!--</button></span>-->
                    <span class="ql-formats" style="height: 24px" title="上传图片">
					<el-upload
                            class="upload-demo"
                            :show-file-list="false"
                            :action="uploadurl"
                            :data="dataToken"
                            :before-upload="isImage"
                            :on-success="handleSuccess"
                            multiple
                            accept="image/*">
						<el-button size="large" icon="el-icon-picture"></el-button>
					</el-upload>
				</span>
                    <!--<span class="ql-formats"><button type="button" class="ql-video"></button></span>-->
                    <span class="ql-formats"><button type="button" class="ql-clean" title="清除样式"></button></span>
                </div>
                <div class="no-tool gray1" v-else>(无工具栏，富文本编辑器)</div>
            </div>
        </quilleditor>
        <div class="tr char-total">{{charTotal}}字 <span v-if="num">/ 限{{num}}字</span></div>
    </section>
</template>

<script>
	import {quillEditor} from 'vue-quill-editor'
	import {getUploadToken} from '../api/api';

	import * as Quill from 'quill'  //引入编辑器
	//quill编辑器的字体
    let Size = Quill.import('attributors/style/size')  
    Size.whitelist = [ '12px', '14px', '15px', '16px', '18px', '20px', '24px', '32px', '40px']
	Quill.register(Size, true)
	export default {
        props:['value','tool','num','maxHeight','placeholderMsg'],
		data() {
			return {
				content: '',
                mHeight: this.maxHeight || 55,
                charTotal: 0, // 实时统计字数
				editorOption: {
					modules: {
						toolbar: '#toolbar2'
					},
                    placeholder: this.placeholderMsg || '请输入内容',
				},
				uploadurl: process.env.VUE_APP_Qiniu, //上传地址
				dataToken: {},//上传文件需要携带的参数
                API_ROOT_ImgUrl:'',//默认图片host
			}
		},
		methods: {
			//获取上传文件的token
			getUploadTokenM (){
				getUploadToken().then((res) => {
                    console.log(res)
					this.dataToken = {token: res.data.uptoken};
                    this.API_ROOT_ImgUrl ="//" + res.data.url+"/";
				});
			},
			//失去焦点
			onEditorBlur(editor) {
				this.$emit('getContent', this.content)
			},
			//获取焦点
			onEditorFocus(editor) {
				// console.log('editor focus!', editor)
			},
			//初始化编辑器
			onEditorReady(editor) {
				this.content = this.value;
			},
			//编辑器内容改变
			onChange(){
                this.charTotal = this.htmldecode(this.content).length
			    if(this.num && this.htmldecode(this.content).length > this.num){
                    this.$message({
                        message: '内容不能超过' + this.num + '字',
                        type: 'error'
                    });
				}
                this.$emit('getContent', this.content)
			},
			//上传头像成功回调
			handleSuccess(response, file, fileList){
                let host = process.env.API_ROOT_ImgUrl || this.API_ROOT_ImgUrl;
                let index = this.editor.getSelection() ?this.editor.getSelection().index:0;
                console.log('handleSuccess:',process.env.API_ROOT_ImgUrl , this.API_ROOT_ImgUrl, response.key);
				this.editor.insertEmbed(index, 'image', host + response.key);
			},
		},
		computed:{
			editor() {
				return this.$refs.myTextEditor.quill;
			}
		},
		components: {
			'quilleditor': quillEditor
		},
		mounted(){
			//获取7牛token
			this.getUploadTokenM()
		},
		watch:{
			'value'(newVal, oldVal) {
				if (this.editor) {
					if (newVal !== oldVal) {
						this.content = newVal
					}
				}
			},
            'maxHeight'(newVal, oldVal) {
                if (this.editor) {
                    if (newVal !== oldVal) {
                        this.mHeight = newVal
                    }
                }
            },
            'placeholderMsg'(newVal, oldVal) {
                if (this.editor) {
                    if (newVal !== oldVal) {
                        this.placeholderMsg = newVal
                    }
                }
            },
		}
	}
</script>
